import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { CommonService } from "../../core/services/common.service";

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() isCondensed = false;
    menu: any;
    menulist: any;
    pathname;

    @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef;

    constructor() {
    }

    ngOnInit() {
        this.pathname = window.location.pathname;
        this.getMenuList();
    }

    getMenuList() {
        this.menulist = [
            { name: 'Hotel Management', link: 'hotel-management', icon: 'remixicon-hotel-bed-line', children: [] },
        ];
        if (window.location.pathname.includes("hotels")) {

            this.menulist = [
                /* { name: 'Home Page', link: '/hotels', icon: 'remixicon-home-2-line', children: [] }, */
                {
                    name: 'Dashboard', link: '#', icon: 'remixicon-dashboard-line', slug_name: 'dashboard', children: [
                        {
                            name: 'My Dashboard',
                            link: '/hotels/my-dashboard',
                            icon: 'fa fa-star float-right text-primary'
                        },
                        //{ name: 'Edit My Dashboard', link: '/hotels/edit-my-dashboard' },
                        // {name: 'Default Dashboard', link: '/hotels/dashboard'}
                    ]
                },
                {
                    name: 'Quicklinks',
                    link: '/hotels/quicklink',
                    slug_name: 'show-to-all',
                    icon: 'fas fa-link',
                    children: []
                },
                { name: 'Manage Image Gallery', link: '/hotels/Image-gallery', slug_name: 'pages', icon: 'fas fa-image', children: [] },

                { name: 'Pages', link: '/hotels/cms', slug_name: 'pages', icon: 'remixicon-pages-fill', children: [] },

                {
                    name: 'Restaurant', link: '#', icon: 'fas fa-utensils', slug_name: 'restaurant-booking', children: [
                        { name: 'Manage Bookings', link: '/hotels/RestaurantBooking-kanban' },
                        { name: 'Booking List', link: '/hotels/RestaurantBookingList' },
                        { name: 'Booking calendar', link: '/hotels/RestaurantBooking' },
                        { name: 'Manage Restaurants', link: '/hotels/RestaurantList' },
                    ]
                },
                {
                    name: 'Spa', link: '#', icon: 'fas fa-spa', slug_name: 'spa-booking', children: [
                        { name: 'Manage Bookings', link: '/hotels/SpaBooking-Kanban' },
                        { name: 'Booking List', link: '/hotels/SpaBookingList' },
                        { name: 'Booking Calendar', link: '/hotels/SpaBooking' },
                        { name: 'Manage Packages', link: '/hotels/spa-package' },
                        { name: 'Manage Packages languages', link: '/hotels/spa-package-name' },
                        { name: 'Manage Package Category', link: '/hotels/spa-category-list' },
                        { name: 'Packages Category Languages', link: '/hotels/spa-category-cms' },
                        { name: 'Manage Therapists', link: '/hotels/ListTherapist' },
                        { name: 'Therapist Calendar', link: '/hotels/TherapistAvailability' },

                    ]
                },

                {
                    name: 'Bike', link: '#', icon: 'fa fa-motorcycle', slug_name: 'bike-booking', children: [
                        { name: 'Manage Bookings', link: '/hotels/BikeBooking-kanban' },
                        { name: 'Booking List', link: '/hotels/BookingBikeList' },
                        { name: 'Bookings Calendar', link: '/hotels/BikeBooking' },
                        { name: 'Manage Bike', link: '/hotels/ListBike' },


                    ]
                },
                {
                    name: 'Boat House', link: '#', icon: 'fas fa-ship', slug_name: 'boat-booking', children: [
                        { name: 'Manage Bookings', link: '/hotels/BoatBooking-kanban' },
                        { name: 'Booking List', link: '/hotels/BoatbookingList' },
                        { name: 'Bookings Calendar', link: '/hotels/BoatBooking' },
                        { name: 'Manage Activity', link: '/hotels/activity' },
                        { name: 'Manage Boat', link: '/hotels/ListBoats' },
                    ]
                },

                {
                    name: 'Room Service', link: '#', icon: 'fa fa-bed', slug_name: 'room-service-booking', children: [
                        { name: 'Manage Bookings', link: '/hotels/Roombooking-kanban' },
                        { name: 'Booking List', link: '/hotels/RoomServiceBookingList' },
                        { name: 'Service Availability', link: '/hotels/service-availability' },
                        { name: 'Booking Calendar', link: '/hotels/Roombooking' },
                        { name: 'Manage Items List', link: '/hotels/item-name' },
                        { name: 'Manage Service Details', link: '/hotels/room-service' },
                    ]
                },

                {
                    name: 'Amenities', link: '#', icon: 'fas fa-building', slug_name: 'amenity-booking', children: [
                        { name: 'Manage  Bookings', link: '/hotels/AmenityBooking-Kanban' },
                        { name: 'Booking List', link: '/hotels/AmenityBookingList' },
                        { name: 'Booking Calendar', link: '/hotels/AmenityBooking' },
                        { name: 'Manage Item Names', link: '/hotels/ListAmenity' },
                        { name: 'Manage Item language', link: '/hotels/CmsAmenity' },
                    ]
                },
                {
                    name: 'Check In', link: '#', icon: 'fa fa-check', slug_name: 'check-in', children: [
                        { name: 'Manage Check In', link: '/hotels/CheckInList' },
                    ]
                },
                { name: 'Customers', link: '/hotels/customers', slug_name: 'pages', icon: 'fa fa-users', children: [] },
                { name: 'Place of Interest', link: '#', slug_name: 'pages', icon: 'fas fa-place-of-worship', children: [
                    { name: 'Place of Interest Languages', link: '/hotels/area-of-interest-cms' },
                    { name: 'Manage Place of Interest', link: '/hotels/area-of-interest' },

                ] },
                {
                    name: 'SLA', link: '#', icon: 'fas fa-clock', slug_name: 'check-in', children: [
                        { name: 'SLA list', link: '/hotels/SLAList' },
                        { name: 'Manage SLA', link: '/hotels/ManageSla' },
                    ]
                },
                {
                    name: 'Events', link: '#', icon: 'remixicon-calendar-2-fill', slug_name: 'events', children: [
                        { name: 'Events Planning', link: '/hotels/ListEvent' },
                    ]
                },

                {
                    name: 'Reviews & Ratings',
                    link: '#',
                    icon: 'fa fa-thumbs-up',
                    slug_name: 'reviews-ratings',
                    children: [
                        { name: 'Reviews List', link: '/hotels/ReviewsList' },
                        { name: 'Action List', link: '/hotels/TasksList' },
                        { name: 'Questions List', link: '/hotels/ManageQuestion' },
                        { name: 'Questions Mapping', link: '/hotels/QuestionList' },
                        { name: 'Questions Language', link: '/hotels/CmsRatingQuestion' },
                    ]
                },
                {
                    name: 'User Management',
                    link: '#',
                    icon: 'remixicon-settings-2-fill',
                    slug_name: 'user-management',
                    children: [
                        { name: 'User List', link: '/hotels/AdminList' },
                        { name: 'Role List', link: '/hotels/RoleList' },
                    ]
                },
                {
                    name: 'Beacons', link: '#', icon: 'remixicon-broadcast-fill', slug_name: 'beacons', children: [
                        { name: 'Geo Location', link: '/hotels/ListGeoLocation' },
                        { name: 'Beacon Message', link: '/hotels/ListBeaconMessage' },
                    ]
                },
                { name: 'Settings', link: '/hotels/settings', slug_name: 'pages', icon: 'remixicon-settings-3-line', children: [] },

            ];
            let permissions = JSON.parse(localStorage.getItem("PermissionList"));
            this.menulist = this.menulist.filter(function (value) {
                return permissions.includes(value.slug_name);
            }, permissions);
        }

    }

    ngAfterViewInit() {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);

        this._activateMenuDropdown();
    }

    ngOnChanges() {
        if (!this.isCondensed && this.sideMenu || this.isCondensed) {
            setTimeout(() => {
                this.menu = new MetisMenu(this.sideMenu.nativeElement);
            });
        }
        else if (this.menu) {
            this.menu.dispose();
        }
    }


    getClass(path) {
        return (window.location.pathname.includes(path)) ? 'active' : ''
    }
    /**
     * small sidebar
     */
    smallSidebar() {
        document.body.classList.add('left-side-menu-sm');
        document.body.classList.remove('left-side-menu-dark');
        document.body.classList.remove('topbar-light');
    }

    /**
     * Dark sidebar
     */
    darkSidebar() {
        document.body.classList.remove('left-side-menu-sm');
        document.body.classList.toggle('left-side-menu-dark');
    }

    /**
     * Light Topbar
     */
    lightTopbar() {
        document.body.classList.add('left-side-menu-dark');
        document.body.classList.add('topbar-light');
        document.body.classList.remove('left-side-menu-sm');
        document.body.classList.remove('enlarged');

    }

    /**
     * Sidebar collapsed
     */
    sidebarCollapsed() {
        document.body.classList.add('enlarged');
        document.body.classList.remove('left-side-menu-dark');
        document.body.classList.remove('left-side-menu-sm');
        document.body.classList.remove('boxed-layout');

    }

    /**
     * Boxed Layout
     */
    boxedLayout() {
        document.body.classList.toggle('boxed-layout');
        document.body.classList.remove('left-side-menu-dark');
        document.body.classList.add('enlarged');
    }

    /**
     * Activates the menu dropdown
     */
    _activateMenuDropdown() {
        const links = document.getElementsByClassName('side-nav-link-ref');
        let menuItemEl = null;
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < links.length; i++) {
            // tslint:disable-next-line: no-string-literal
            if (window.location.pathname === links[i]['pathname']) {
                menuItemEl = links[i];
                break;
            }
        }

        if (menuItemEl) {
            menuItemEl.classList.add('active');

            const parentEl = menuItemEl.parentElement;
            if (parentEl) {
                parentEl.classList.add('active');

                const parent2El = parentEl.parentElement;
                if (parent2El) {
                    parent2El.classList.add('in');
                }

                const parent3El = parent2El.parentElement;
                if (parent3El) {
                    parent3El.classList.add('mm-active');
                    parent3El.firstChild.classList.add('mm-active');
                }
                // const parent4El = parent3El.parentElement;
                // if (parent4El) {
                //     menuItemEl.classList.remove('mm-active');
                //     // parent3El.classList.add('mm-active');
                //     // parent3El.firstChild.classList.add('mm-active');
                // }
            }
        }
    }

}
